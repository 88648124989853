import './App.css';

import wordsJson from "./words.json"

function generatePassphrase(words, len) {
    const fragments = []
    for (let i = 0; i < len; i++) {
        fragments.push(words[Math.floor(Math.random() * words.length)]);
    }
    return fragments.join(" ");
}

function App() {
    const words = wordsJson["words"];
    const passphrase = generatePassphrase(words, 4);
    return (
        <div className="flex justify-center container mx-auto h-screen">
            <div className="m-auto text-4xl font-light">
                {passphrase}
            </div>
        </div>
    );
}

export default App;
